import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout-pipefy"
import Seo from "../components/seo"

//components

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import Hero from "../components/hero/hero-pipefy"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const Cases = loadable(() =>
  pMinDelay(import("../components/cases-custom"), 500)
)
const Modal = loadable(() =>
  pMinDelay(import("../components/modal/modal-pipefy"), 500)
)
const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/partners.js"), 500)
)
const ExitIntentModal = loadable(() =>
  pMinDelay(import("../components/modal/exit-intent-email"), 500)
)

class PipefyWebinarPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo title={siteTitle} description={siteDescription} />
        <div id="main">
          <LazyLoadComponent>
            <section
              id="quandary-help-wrapper"
              className="quandary-help-wrapper mt-5"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h2 className="font-weight-medium">
                      ORGANIZATIONS <span className="text-green">TRUST</span>{" "}
                      QUANDARY CONSULTING GROUP
                    </h2>
                  </div>
                </div>
                <LazyLoadComponent>
                  <PartnerSwiper />
                </LazyLoadComponent>
              </div>
            </section>
            <ExitIntentModal />
            <section
              className="quickbase-support"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h1>STRESS-FREE BUSINESS PROCESS IMPROVEMENT</h1>
                    <p>
                      Outsource the stress and frustration of system overhauls
                      to specialists. We’ll transform your workflows, helping
                      you compete with enterprise organizations effortlessly
                      (without the enterprise price tag).
                    </p>
                    <div className="pt-5">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="quickbase-integrations">
              <div className="container quickbase-integrations-padding">
                <div className="row">
                  <div className="col-lg-12 text-center pt-5">
                    <h3 className="text-white mt-4">
                      TEAM UP WITH EXPERTS IN BUSINESS PROCESS IMPROVEMENT
                    </h3>
                    <p className="text-white">
                      Streamlining your systems can be an expensive nightmare.
                      We help you avoid roadblocks, slowdowns, and costly
                      mistakes.
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/coding-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      ENORMOUSLY RESPONSIVE
                    </p>
                    <p className="pt-2 text-white">
                      Get your questions answered and your concerns addressed in
                      hours (not days or weeks).
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/Drag-Icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      OWN YOUR CODE
                    </p>
                    <p className="pt-2 text-white">
                      We don’t hold your code hostage. We build the apps. You
                      control the code.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/dashboard-icons.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      10X Faster
                    </p>
                    <p className="pt-2 text-white">
                      Delegate the heavy lifting to us and get more time to
                      focus on your core business concerns.
                    </p>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/phone-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      EFFORTLESS ONBOARDING
                    </p>
                    <p className="pt-2 text-white">
                      We train your team on new systems directly to ensure
                      adoption and success.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/automate-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      REDUCE IT BURDEN
                    </p>
                    <p className="pt-2 text-white">
                      Give IT more time by outsourcing improvements to our low
                      code experts.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/roi-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      ENHANCED ROI
                    </p>
                    <p className="pt-2 text-white">
                      We uncover major inefficiencies through extensive
                      discovery and build custom solutions.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="what-to-expect" style={{ padding: "5rem 0" }}>
              <div className="container pb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="font-weight-medium">
                      BUSINESS PROCESS SUPPORT YOU’VE DREAMED ABOUT
                    </h2>
                    <p className="mt-3">
                      As your process improvement specialists, we leverage the
                      power of Pipefy and our experience to transform how work
                      gets done in your business.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      WORKFLOW MAPPING
                    </p>
                    <p className="my-3 pb-3">
                      We analyze your workflows before building solutions
                      <br />
                      to find the root cause of your problems.
                    </p>
                  </div>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      UNMATCHED EXPERIENCE
                    </p>
                    <p className="my-3 pb-3">
                      We’ve built a range of applications and automations <br />
                      from scratch for businesses large and small.
                    </p>
                  </div>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      REDUCE IT SPEND
                    </p>
                    <p className="my-3 pb-3">
                      We use Pipefy and other low-code platforms
                      <br /> to lower the cost of custom development
                      significantly.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 mx-auto text-center pt-5">
                  <Modal />
                </div>
              </div>
            </section>

            <section
              id="it-consulting"
              className="it-consulting"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div className="container-fluid px-5">
                <div className="row">
                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">
                      Awesome Collaborative Partners
                    </h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "We have been working with Quandary for a year or two now.
                      They are great to work with and are very collaborative in
                      working with you to develop tools that will work best for
                      your organization. The development process from the
                      customer perspective is always simple and easy. The team
                      at Quandary is always very responsive to our needs, and
                      goes above and beyond to ensure that we are satisfied
                      customers."
                    </p>
                    <div className="py-3">
                      <p>- Thomas Arlow, Dexko</p>
                    </div>
                  </div>

                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">
                      Customized program made simple...
                    </h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "This team does a great job of communicating changes,
                      scheduling calls to chat, and doing what they can to
                      create the program the business needs and wants. They are
                      fun to chat with, real people who take on new challenges
                      and make them a reality."
                    </p>
                    <div className="py-3">
                      <p>
                        - Carmen Whiting, Total Package Professional Services
                        LLC
                      </p>
                    </div>
                  </div>
                  <div className="col text-center mt-5 pb-5 px-5 mx-3">
                    <div className="pb-3">
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                      <span className="fa fa-star checked px-2"></span>
                    </div>
                    <h3 className="text-green">THAT WAS QUICK...</h3>
                    <p className="my-4" style={{ minHeight: "5rem" }}>
                      "Finding a vendor that not only responds in a timely
                      manner but also proactively identifies potential
                      roadblocks is like finding a needle in a hay stack. They
                      diagnosed problems with surgical precision and with the
                      efficiency of an energy star appliance. We look forward to
                      working Quandary again in the future. If you have a tech
                      project that could use some help, be sure to give Quandary
                      a call."
                    </p>
                    <div className="py-3">
                      <p>
                        - Ryan, Loop Media, Inc.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="case-studies-container">
              <div id="case-studies" className="bg-indigo">
                <div className="container-fluid">
                  <div className="intro text-center">
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <p
                          className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                          style={{ fontFamily: "Futura,sans-serif" }}
                        >
                          Case Studies
                        </p>
                        <h2 className=" my-2 text-white">
                          We Save Clients 1000s of Hours. Every Year.
                        </h2>
                        {/* <p className="text-white">
                          We helped a national healthcare provider save over
                          9,000 hours in weekly labor costs by developing a
                          single application...
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Cases
                    allSanityCaseStudy={this.props.data.allSanityCaseStudy}
                  />
                </div>
              </div>
            </section>

            <section id="case-studies-container">
              <div id="case-studies">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-center">
                      <h2 className="my-2 font-weight-medium">
                        {/* YOUR BUSINESS{" "} */}
                        <span className="text-green">
                          YOUR BUSINESS REINVENTED
                        </span>
                      </h2>
                      <p>
                        Delegate the heavy lifting to your process improvement
                        partners and discover a much (Faster, More Efficient,
                        Less Expensive)
                        <br /> way of running your business.
                      </p>
                      <div className="col-lg-5 mx-auto text-center pt-3 ">
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default PipefyWebinarPage

export const PipefyWebinarPageQuery = graphql`
  query PipefyWebinarPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
